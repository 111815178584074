<template>
  <div>
    <v-row>
      <v-spacer />
      <file-manager
        :readonly="readonly"
        :selection.sync="selection"
        :queryConfig="queryConfig"
        v-if="reloadFileManager"
      />
    </v-row>
    <v-container>
      <v-row>
        <v-switch
          v-model="readonly"
          :label="$t('t.ReadOnly')"
        />
      </v-row>
      <v-row>
        {{selection}}
      </v-row>
      <v-row>
        <v-text-field
          v-model="localPath"
          :label="$t('t.LocalFile')"
        />
        <v-btn @click="setSelection(localPath)">Local file</v-btn>
        <v-btn @click="setSelection([])">Empty array</v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {
    FileManager: () => import('@/components/file-manager')
  },
  data () {
    return {
      selection: undefined,
      localPath: '',
      readonly: false,
      reloadFileManager: true,
      queryConfig: undefined
    }
  },
  methods: {
    reload () {
      this.reloadFileManager = false
      this.$nextTick(() => (this.reloadFileManager = true))
    },
    setSelection (v) {
      if (typeof v === 'string') {
        this.queryConfig = { dataSourceFolder: 'default' }
      } else {
        this.queryConfig = undefined
      }
      this.selection = v
      this.reload()
    }
  },
  watch: {
    readonly () {
      this.reload()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
